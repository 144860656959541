<!-- 提现申请 -->
<template>
  <div class="withdrawalApply">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="statusValue" placeholder="选择状态" @change="(value) => handleSelect(value, 'status')"
          clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="(value) => handleSelect(value, 'data')" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-input v-model="orderValue" placeholder="搜索申请单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="schoolValue" placeholder="搜索学校名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="riderValue" placeholder="搜索骑手名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyNum" label="申请单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyUserName" label="骑手姓名"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="schoolName" label="学校名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountType" label="账户类型">
            <template slot-scope="scope">
              <span>{{ scope.row.accountType == 1 ? '支付宝' : '银行卡' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountName" label="账户名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountNum" label="账号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="availableAmount"
            label="可提现金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyAmount" label="提现金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="申请时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="status" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '审核通过' : '审核不通过' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="180" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handlePass(scope.row)" v-if="scope.row.status == 0">通过</el-button>
              <el-button type="danger" plain @click="handleUnpass(scope.row)"
                v-if="scope.row.status == 0">不通过</el-button>
              <el-button type="primary" plain @click="handleCheck(scope.row)"
                v-if="scope.row.status != 0">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>


    <!-- 审核不通过原因弹窗-->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" width="1244px" :before-close="unpassResonDialogClose">
      <el-form :model="dialogRuleForm" :rules="dialogRules" ref="dialogRuleForm" label-width="160px"
        class="demo-ruleForm">
        <el-row class="dialog_row">
          <el-col :span="24">
            <el-form-item label="审核不通过原因" prop="unpassReason">
              <el-input v-model="dialogRuleForm.unpassReason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="unpassResonDialogClose">取 消</el-button>
        <el-button type="primary" @click="unpassResonDialogSubmitForm('dialogRuleForm')">提 交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [
        { label: "待审核", value: '0' },
        { label: "审核通过", value: '1' },
        { label: "审核不通过", value: '2' },
      ],
      statusValue: '', // 审核状态
      dataValue: '', // 时间区间
      orderValue: '', // 搜索申请单号
      schoolValue: '', // 搜索学校名称
      riderValue: '', // 搜索骑手名称
      // 表格数据
      tableData: [],
      dialogVisible: false, // 不通过弹窗
      dialogRuleForm: {
        unpassReason: '',
      },
      dialogRules: {
        unpassReason: [{ required: true, message: '请输入不通过原因', trigger: 'blur' }],
      },
      unpassId: '',
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getRiderExtractApplyList();
  },
  methods: {
    // 获取列表
    getRiderExtractApplyList() {
      this.loading = true;
      let params = {
        status: this.statusValue || null, // 审核状态
        applyNum: this.orderValue || null, // 搜索申请单号
        schoolName: this.schoolValue || null, // 搜索学校名称
        riderName: this.riderValue || null, // 搜索骑手名称
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getRiderExtractApplyList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 不通过
    handleUnpass(row) {
      this.dialogVisible = true;
      this.unpassId = row.id;
    },
    //  通过
    handlePass(row) {
      console.log(row);
      this.$confirm('确定该审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(`${this.$api.passRiderExtractApply}/${row.id}`).then(() => {
          this.$message.success('审核成功！')
          this.getRiderExtractApplyList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消审核'
        });
      });
    },

    // 审核不通过弹窗-确定
    unpassResonDialogSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.notpassRiderExtractApply, {
            auditMsg: this.dialogRuleForm.unpassReason,
            id: this.unpassId,
          }).then(() => {
            this.getRiderExtractApplyList();
            this.$message.success('审核成功！')
          })
          this.unpassResonDialogClose();
        } else {
          return false;
        }
      });
    },
    // 关闭审核不通过弹窗
    unpassResonDialogClose() {
      this.dialogVisible = false;
      this.dialogRuleForm = {};
      // 重置校验
      this.$refs.dialogRuleForm.resetFields();
    },
    // 查看详情
    handleCheck(row) {
      this.$router.push({ path: "/settlement/detail/applyDetail", query: { 'id': row.id } });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getRiderExtractApplyList();
    },
    // 选择
    handleSelect(value, type) {
      if (type == 'status') {
        this.statusValue = value;
      } else {
        this.dataValue = value;
      }
      this.clearData();
      this.getRiderExtractApplyList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getRiderExtractApplyList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getRiderExtractApplyList();
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 10) {
        if (row.status == '0') {
          return { color: 'red' }
        }
      }
    },
  }

}



</script>

<style scoped lang="scss">
.withdrawalApply {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;

    }
  }
}

// 审核不通过原因弹窗
::v-deep .el-textarea__inner {
  resize: none;
  background-color: #f2f2f2;
  border-radius: 10px;
  height: 150px;
  border: none;
}

::v-deep .el-input__count {
  background-color: #f2f2f2;
}
</style>
